import { useEffect, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import KickLogo from '../../resources/kick.png';
import { discordAuthLink } from '../../config';

const Header = () => {
  const navigate = useNavigate();
  const user = useSelector(({ UserReducer }) => UserReducer.user);
  const [isLiveOnKick, setIsLiveOnKick] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDiscordLogin = async () => {
    const popupWindow = window.open(`${discordAuthLink}`, '_blank', 'width=500, height=700');
    if (window.focus) popupWindow.focus();
  };

  useEffect(() => {
    fetch('https://kick.com/api/v1/channels/buhlockay')
      .then((response) => response.json())
      .then((data) => setIsLiveOnKick(data.livestream));
  }, []);

  return (
    <header className="header">
      <div className="container d-flex justify-content-between align-items-center">
        <div className="header-logo">
          <NavLink to="/">
            <img src="/logo.png" alt="logo" className="img-fluid" />
          </NavLink>
        </div>
        <div className="header-menu d-lg-block d-none">
          <ul className="pl-0 mb-0 d-flex align-items-center">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/top-wagers">Top Wagers</NavLink>
            </li>
            <li>
              <NavLink to="/tournament-bracket">Tournament Bracket</NavLink>
            </li>
            <li>
              <NavLink to="/rewards">Rewards</NavLink>
            </li>
            <li>
              <NavLink to="/bonus-hunt">Bonus Hunt</NavLink>
            </li>
            {user?.isAdmin && (
              <li>
                <NavLink to="/admin">Admin</NavLink>
              </li>
            )}
          </ul>
        </div>
        <div className="header-discord-text d-lg-block d-none">
          <a
            href="https://kick.com/buhlockay"
            style={{ textDecoration: 'none' }}
            target={'_blank'}
            rel="noreferrer">
            <div className={`streamStatus ${isLiveOnKick ? 'live' : ''}`}>
              <div className="streamStatusLogo">
                <div className="ring-container">
                  <div className="ringring"></div>
                  <div className="circle"></div>
                </div>
                <img src={KickLogo} alt="" />
              </div>
              <div className="streamStatusText">{isLiveOnKick ? 'LIVE' : 'OFFLINE'}</div>
            </div>
          </a>
          {user ? (
            <a className="btn btn-primary" onClick={() => navigate(`/account`)}>
              {user.displayName}
            </a>
          ) : (
            <a className="btn-buhlockay sm" onClick={handleDiscordLogin}>
              Login w/ <img src="/discord.svg" alt="discord" className="img-fluid" />
            </a>
          )}
        </div>
        <i
          className="fa-solid fa-bars small-menu-btn d-inline-block d-lg-none ms-auto order-1"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
          onClick={handleShow}></i>
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <NavLink to="/" onClick={handleClose}>
                Home
              </NavLink>
            </ListGroup.Item>

            <ListGroup.Item>
              <NavLink to="/top-wagers" onClick={handleClose}>
                Top Wagers
              </NavLink>
            </ListGroup.Item>
            <ListGroup.Item>
              <NavLink to="/tournament-bracket" onClick={handleClose}>
                Tournament Bracket
              </NavLink>
            </ListGroup.Item>
            <ListGroup.Item>
              <NavLink to="/rewards" onClick={handleClose}>
                Rewards
              </NavLink>
            </ListGroup.Item>
            <ListGroup.Item>
              <NavLink to="/bonus-hunt" onClick={handleClose}>
                Bonus Hunt
              </NavLink>
            </ListGroup.Item>
            {/*<ListGroup.Item><NavLink to="/leaderboards">Leaderboard</NavLink></ListGroup.Item>*/}
            {/*<ListGroup.Item><NavLink to="/bonus-hunt">Bonus Hunt</NavLink></ListGroup.Item>*/}
            {/*<ListGroup.Item><NavLink to="/tournament">Tournament</NavLink></ListGroup.Item>*/}
            {/*<ListGroup.Item><NavLink to="/gamdom-rewards">Gamdom Rewards</NavLink></ListGroup.Item>*/}
            {/*<ListGroup.Item><NavLink to="/hypedrop-rewards">Hypedrop Rewards</NavLink></ListGroup.Item>*/}
            {/*{user ? (<ListGroup.Item><NavLink to="/account">Account: {user.name}</NavLink></ListGroup.Item>):(*/}
            {/*    <ListGroup.Item><a href="#" onClick={handleDiscordLogin}>Sign In</a></ListGroup.Item>*/}
            {/*)}*/}
            {/*{user?.isAdmin && (<ListGroup.Item>*/}
            {/*    <NavLink to="/admin">Admin</NavLink>*/}
            {/*</ListGroup.Item>)}*/}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

export default Header;
