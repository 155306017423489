import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADMIN_GETGUESSESBYHUNTID, GET_HUNTBYHUNTID } from '../../queries/queries';
import { Spinner } from 'reactstrap';
import { formatToCurrency, sweetAlertToast } from '../../helpers';
import {
  ADMIN_TOGGLEGTB,
  ADMIN_UPDATEFIRSTPLACE,
  ADMIN_UPDATEHUNTPAYOUT,
  ADMIN_UPDATEMULTIPLACE,
  ADMIN_UPDATESECONDPLACE,
  ADMIN_UPDATETHIRDPLACE
} from '../../queries/mutations';

const AdminGTBView = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [bonusHunts, setBonusHunts] = useState([]);
  const [dbHunt, setDbHunt] = useState([]);
  const [guesses, setGuesses] = useState([]);

  const [finalPrediction, setPrediction] = useState(null);
  const [finalPredictionMulti, setPredictionMulti] = useState(null);

  const [firstPlaceWinner, setFirstPlaceWinner] = useState(null);
  const [secondPlaceWinner, setSecondPlaceWinner] = useState(null);
  const [thirdPlaceWinner, setThirdPlaceWinner] = useState(null);
  const [predictionMultiWinner, setPredictionMultiWinner] = useState(null);

  const [getGuesses, { loading, refetch, startPolling, stopPolling }] = useLazyQuery(
    ADMIN_GETGUESSESBYHUNTID,
    {
      variables: {
        id: parseInt(params.id)
      },
      pollInterval: 2500,
      fetchPolicy: 'cache-and-network',
      onCompleted: (e) => {
        setGuesses(e.getGuessesByHuntId ?? []);
      },
      onError: () =>
        console.log('API Request failed - if this keeps happening, report to developer!')
    }
  );

  const [dbHuntQuery, { dbloading, dbrefetch }] = useLazyQuery(GET_HUNTBYHUNTID, {
    variables: {
      huntId: parseInt(params.id)
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (e) => {
      setDbHunt(e.getHuntByHuntId ?? []);
    },
    onError: () => console.log('API Request failed - if this keeps happening, report to developer!')
  });

  const [toggleGTB] = useMutation(ADMIN_TOGGLEGTB);
  const [updateHuntPayout] = useMutation(ADMIN_UPDATEHUNTPAYOUT);
  const [updateFirstPlace] = useMutation(ADMIN_UPDATEFIRSTPLACE);
  const [updateSecondPlace] = useMutation(ADMIN_UPDATESECONDPLACE);
  const [updateThirdPlace] = useMutation(ADMIN_UPDATETHIRDPLACE);
  const [updateMultiWinner] = useMutation(ADMIN_UPDATEMULTIPLACE);

  const disableGtb = () => {
    toggleGTB({
      variables: {
        huntId: parseInt(params.id),
        gtbEnabled: false
      }
    }).then((result) => {
      sweetAlertToast(`GTB disabled!`);
      setDbHunt(result.data.toggleGTB);
    });
  };

  const enableGtb = () => {
    toggleGTB({
      variables: {
        huntId: parseInt(params.id),
        gtbEnabled: true
      }
    }).then((result) => {
      sweetAlertToast(`GTB enabled!`);
      setDbHunt(result.data.toggleGTB);
    });
  };

  const submitPredictions = () => {
    updateHuntPayout({
      variables: {
        huntId: parseInt(params.id),
        payout: parseFloat(finalPrediction),
        multiplier: parseFloat(finalPredictionMulti)
      }
    }).then((result) => {
      sweetAlertToast(`Updated the payout!`);
    });
  };

  const publishFirstPlace = () => {
    updateFirstPlace({
      variables: {
        huntId: parseInt(params.id),
        user: parseFloat(firstPlaceWinner.userId)
      }
    }).then((result) => {
      sweetAlertToast(`First place revealed!`);
    });
  };
  const publishSecondPlace = () => {
    updateSecondPlace({
      variables: {
        huntId: parseInt(params.id),
        user: parseFloat(secondPlaceWinner.userId)
      }
    }).then((result) => {
      sweetAlertToast(`Second place revealed!`);
    });
  };
  const publishThirdPlace = () => {
    updateThirdPlace({
      variables: {
        huntId: parseInt(params.id),
        user: parseFloat(thirdPlaceWinner.userId)
      }
    }).then((result) => {
      sweetAlertToast(`Third place revealed!`);
    });
  };

  const findWinners = () => {
    const closestPrediction = guesses.reduce(function (prev, curr) {
      return Math.abs(curr.prediction - finalPrediction) <
        Math.abs(prev.prediction - finalPrediction)
        ? curr
        : prev;
    });
    setFirstPlaceWinner(closestPrediction);

    let extraGuesses = guesses.filter(function (obj) {
      return obj.id !== closestPrediction.id;
    });

    if (extraGuesses.length) {
      const secondClosest = extraGuesses.reduce(function (prev, curr) {
        return Math.abs(curr.prediction - finalPrediction) <
          Math.abs(prev.prediction - finalPrediction)
          ? curr
          : prev;
      });
      setSecondPlaceWinner(secondClosest);

      extraGuesses = guesses.filter(function (obj) {
        return obj.id !== secondClosest.id && obj.id !== closestPrediction.id;
      });

      if (extraGuesses.length) {
        const thirdClosest = extraGuesses.reduce(function (prev, curr) {
          return Math.abs(curr.prediction - finalPrediction) <
            Math.abs(prev.prediction - finalPrediction)
            ? curr
            : prev;
        });
        setThirdPlaceWinner(thirdClosest);
      }
    }
  };

  useEffect(() => {
    if (params.id) {
      getGuesses();
      dbHuntQuery();
    }
  }, [params.id]);

  useEffect(() => {
    setPrediction(dbHunt.payout);

    if (dbHunt.firstPlace) {
      const winningGuesser = guesses.filter((row, index) => {
        return row.User.displayName == dbHunt.firstPlace.displayName;
      });

      setFirstPlaceWinner(winningGuesser[0]);
    }
    if (dbHunt.secondPlace) {
      const winningGuesser = guesses.filter((row, index) => {
        return row.User.displayName == dbHunt.secondPlace.displayName;
      });

      setSecondPlaceWinner(winningGuesser[0]);
    }
    if (dbHunt.thirdPlace) {
      const winningGuesser = guesses.filter((row, index) => {
        return row.User.displayName == dbHunt.thirdPlace.displayName;
      });

      setThirdPlaceWinner(winningGuesser[0]);
    }
  }, [dbHunt]);

  useEffect(() => {
    if (dbHunt.gtbEnabled) {
      startPolling(1000);
    } else {
      stopPolling();
    }
  }, [dbHunt.gtbEnabled]);

  return (
    <div className="card text-bg-dark">
      <div className="card-header">GTB ({guesses.length} entries)</div>
      <div className="card-body">
        <div className="btn-group d-flex mb-3">
          {dbHunt.gtbEnabled ? (
            <a onClick={disableGtb} className="btn btn-danger float-end">
              Disable GTB!
            </a>
          ) : (
            <a onClick={enableGtb} className="btn btn-success float-end">
              Enable GTB!
            </a>
          )}
        </div>
        {!dbHunt.gtbEnabled && (
          <div className="row">
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">Final balance & multiplier</div>
                <div className="card-body">
                  <div className="mb-3">
                    <label className="form-label">Final Balance</label>

                    <input
                      type="number"
                      defaultValue={finalPrediction}
                      className="form-control"
                      min="0"
                      step={1}
                      onChange={(e) => setPrediction(e.target.value)}
                    />
                  </div>
                  <div className="btn-group d-flex mb-3">
                    <a onClick={submitPredictions} className="btn btn-success">
                      Update numbers
                    </a>
                    <a
                      onClick={findWinners}
                      className={`btn btn-block btn-info ${finalPrediction > 0 ? '' : 'disabled'}`}>
                      Find winners!
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">Winners</div>
                {(firstPlaceWinner ||
                  secondPlaceWinner ||
                  thirdPlaceWinner ||
                  predictionMultiWinner) && (
                  <div className="card-body">
                    <strong>Top 3 Closest Balance</strong>
                    <p>
                      1st. {firstPlaceWinner?.User.displayName}
                      <br />[{formatToCurrency(finalPrediction - firstPlaceWinner?.prediction)}] (
                      {formatToCurrency(firstPlaceWinner?.prediction)} -{' '}
                      {firstPlaceWinner?.predictionMulti}x) <br />
                      {firstPlaceWinner && (
                        <a onClick={publishFirstPlace} className="btn btn-info btn-sm">
                          Publish
                        </a>
                      )}
                    </p>

                    <p>
                      2nd. {secondPlaceWinner?.User.displayName}
                      <br />[{formatToCurrency(finalPrediction - secondPlaceWinner?.prediction)}] (
                      {formatToCurrency(secondPlaceWinner?.prediction)} -{' '}
                      {secondPlaceWinner?.predictionMulti}x) <br />
                      {secondPlaceWinner && (
                        <a onClick={publishSecondPlace} className="btn btn-info btn-sm">
                          Publish
                        </a>
                      )}
                    </p>

                    <p>
                      3rd. {thirdPlaceWinner?.User.displayName}
                      <br />[{formatToCurrency(finalPrediction - thirdPlaceWinner?.prediction)}] (
                      {formatToCurrency(thirdPlaceWinner?.prediction)} -{' '}
                      {thirdPlaceWinner?.predictionMulti}x) <br />
                      {thirdPlaceWinner && (
                        <a onClick={publishThirdPlace} className="btn btn-info btn-sm">
                          Publish
                        </a>
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="card-body p-0">
        <table className="table table-dark table-striped">
          <thead>
            <tr>
              <th>Discord Name</th>
              <th>Kick Name</th>
              <th>Prediction</th>
            </tr>
          </thead>
          {loading ? (
            <Spinner />
          ) : (
            <tbody>
              {guesses.length > 0 ? (
                <>
                  {guesses.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>{row.User.displayName}</td>
                        <td>{row.kickName}</td>
                        <td>{formatToCurrency(row.prediction)}</td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default AdminGTBView;
