import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { useDispatch } from 'react-redux';
import { doLogout } from '../../store/actions/UserActions';

const AccountIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);

  const doLogoutButton = () => {
    dispatch(doLogout());
    navigate('/');
  };

  return (
    <>
      <div className="container p-0">
        <div className="hero-section">
          <div className="row mx-auto w-100">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="tag mt-0">Account</div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>Account:</strong> {currentUser?.displayName}
            </p>
            <button className="btn btn-danger" onClick={doLogoutButton}>
              Log out
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountIndex;
