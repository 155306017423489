import React, { createContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { setIsLoggedIn, setUser } from '../store/actions/UserActions';
import { GET_SELF } from '../queries/queries';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const isSignedIn = useSelector(({ UserReducer }) => UserReducer.isLoggedIn);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const dispatch = useDispatch();

  const [getSelf, { startPolling, stopPolling }] = useLazyQuery(GET_SELF, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 2500,
    onCompleted: (e) => {
      const user = e.getSelf;
      if (user) {
        setCurrentUser(user);
        setCurrentUserId(user.id);
        dispatch(setUser(user));
        dispatch(setIsLoggedIn(true));
      }
    }
  });

  useEffect(() => {
    if (isSignedIn) {
      getSelf();
      startPolling(2500);
    } else {
      stopPolling();
    }
  }, [isSignedIn]);

  return (
    <AuthContext.Provider value={{ currentUser, currentUserId }}>{children}</AuthContext.Provider>
  );
};
