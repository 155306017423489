import React from 'react';
import bcgameLogo from '../../resources/bcgameLogo.png';
import rewardsTop from '../../resources/rewardsTop.png';
import RewardsBanner from '../../resources/depositBonus.png';
import FollowBanner from '../../resources/vipBonus.png';
import JoinUsBanner from '../../resources/wagerRace.png';
import bcgameWheel from '../../resources/bcgameWheel.png';
import bcgameBanner from '../../resources/bcgameBanner.png';
import bcgameRace from '../../resources/bcgameRace.png';
import bcWelcome from '../../resources/bcWelcome.png';

const RewardsIndex = () => {
  return (
    <>
      <div className="container p-0">
        <div className="hero-section">
          <div className="row mx-auto w-100">
            <div className="col-12">
              <div className="rewardsTopWrapper">
                <div className="rewardsTopLeft">
                  <h2>
                    Get exclusive rewards &<br /> many bonuses on <img src={bcgameLogo} alt="" />
                  </h2>
                  <p>
                    High payouts with small deposit. Play through the highly rewarding offers using
                    the promo code.
                  </p>
                  <a href="https://bit.ly/44SOjjt" className="btn-buhlockay" target={`_blank`}>
                    Use promo code
                  </a>
                </div>
                <div className="rewardsTopRight">
                  <img src={rewardsTop} alt="" />
                </div>
              </div>

              <div className="rewardsColumns">
                <h2>
                  Choose <img src={bcgameLogo} alt="" /> now!
                </h2>
                <p>
                  Claim bonuses now using promo code: <code>MULLET</code>
                </p>
                <div className="infoBoxes">
                  <div className="infoBox">
                    <div className="infoBoxText">
                      <img src={bcgameBanner} alt="" />
                      <img src={RewardsBanner} alt="" />
                      <p>Get 300% deposit bonus of up to $20,000</p>
                      <a href="https://bit.ly/44SOjjt" className="btn-buhlockay" target={`_blank`}>
                        Use promo code
                      </a>
                    </div>
                  </div>
                  <div className="infoBox">
                    <div className="infoBoxText">
                      <img src={bcgameWheel} alt="" />
                      <img src={FollowBanner} alt="" />
                      <p>
                        Earn Cash prizes with VIP Level achieved. Hit VIP 22 to unlock Weekly and
                        Monthly Cashback, Sports Weekly Bonus and Daily recharge
                      </p>
                      <a href="https://bit.ly/44SOjjt" className="btn-buhlockay" target={`_blank`}>
                        Use promo code
                      </a>
                    </div>
                  </div>
                  <div className="infoBox">
                    <div className="infoBoxText">
                      <img src={bcgameRace} alt="" />
                      <img src={JoinUsBanner} alt="" />
                      <p>Weekly payouts for winners of wager race</p>
                      <a href="https://bit.ly/44SOjjt" className="btn-buhlockay" target={`_blank`}>
                        Use promo code
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rewardsColumns">
                <h2>How to register</h2>
              </div>
              <div className="rewardsBottomWrapper">
                <div className="rewardsTopRight">
                  <img src={bcWelcome} alt="" />
                </div>
                <div className="rewardsTopLeft">
                  <ol>
                    <li>1. Fill in email and login password infomation</li>
                    <li>
                      2. Enter promo code <code>MULLET</code>
                    </li>
                    <li>3. Agree to conditions and click on “Sign up” button</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardsIndex;
