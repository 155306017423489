import { gql } from '@apollo/client';

export const HOMEPAGE_VIDEOS = gql`
  query getVideos($limit: Int) {
    getVideos(limit: $limit) {
      id
      title
      thumbnail
      likeCount
      viewCount
      slotMultiplier
      slotPayout
      slotGame
      slotIcon
      publishedAt
    }
  }
`;
export const CONFIG_BY_KEY = gql`
  query getConfigByKey($configKey: String) {
    getConfigByKey(configKey: $configKey) {
      configKey
      configValue
    }
  }
`;
export const HOMEPAGE_VIDEOS_UNBOXING = gql`
  query getVideosUnboxing($limit: Int) {
    getVideosUnboxing(limit: $limit) {
      id
      title
      thumbnail
      likeCount
      viewCount
      slotMultiplier
      slotPayout
      slotGame
      slotIcon
      publishedAt
    }
  }
`;
export const HOMEPAGE_LEADERBOARDCLASHGG = gql`
  query getLeaderboardClashGG($week: Int) {
    getLeaderboardClashGG(week: $week) {
      PlayerName
      PlayerId
      Position
      WageredAmount
      user {
        profileImageUrl
      }
    }
  }
`;
export const HOMEPAGE_LEADERBOARDPACKDRAW = gql`
  query getLeaderboardPackdraw($week: Int) {
    getLeaderboardPackdraw(week: $week) {
      PlayerName
      PlayerId
      Position
      WageredAmount
      user {
        profileImageUrl
      }
    }
  }
`;
export const HOMEPAGE_LEADERBOARDPRIZESCLASHGG = gql`
  query getLeaderboardClashGGPrizes {
    getLeaderboardClashGGPrizes {
      Position
      Reward
    }
  }
`;
export const LEADERBOARD_CLASHGG_LASTSYNC = gql`
  query getLatestClashGGSyncDate {
    getLatestClashGGSyncDate {
      updatedAt
    }
  }
`;
export const LEADERBOARD_PACKDRAW_LASTSYNC = gql`
  query getLatestPackdrawSyncDate {
    getLatestPackdrawSyncDate {
      updatedAt
    }
  }
`;
export const GET_SELF = gql`
  query getSelf {
    getSelf {
      id
      displayName
      playerName
      profileImageUrl
      discordId
      isAdmin
    }
  }
`;

export const HOMEPAGE_LEADERBOARD = gql`
  query getLeaderboard {
    getLeaderboard {
      PlayerName
      PlayerId
      Position
      WageredAmount
      user {
        profileImageUrl
      }
    }
  }
`;
export const HOMEPAGE_LEADERBOARDPRIZES = gql`
  query getLeaderboardPrizes {
    getLeaderboardPrizes {
      Position
      Reward
    }
  }
`;
export const LEADERBOARD_LASTSYNC = gql`
  query getLatestSyncDate {
    getLatestSyncDate {
      updatedAt
    }
  }
`;
export const GET_HUNTBYHUNTID = gql`
  query getHuntByHuntId($huntId: Int) {
    getHuntByHuntId(huntId: $huntId) {
      id
      huntId
      gtbEnabled
      payout
      multiplier
      firstPlace {
        displayName
        profileImageUrl
      }
      firstPlaceGuess {
        prediction
        kickName
      }
      firstPlacePrize
      secondPlace {
        displayName
        profileImageUrl
      }
      secondPlaceGuess {
        prediction
        kickName
      }
      secondPlacePrize
      thirdPlace {
        displayName
        profileImageUrl
      }
      thirdPlaceGuess {
        prediction
        kickName
      }
      thirdPlacePrize
      multiWinner {
        displayName
        profileImageUrl
      }
      multiWinnerPrize
    }
  }
`;

export const GET_GUESSBYUSERANDHUNT = gql`
  query getGuessByUserIdAndHuntId($huntId: Int, $userId: Int) {
    getGuessByUserIdAndHuntId(huntId: $huntId, userId: $userId) {
      prediction
      kickName
    }
  }
`;

export const GET_GUESSMULTIBYUSERANDHUNT = gql`
  query getGuessMultiByUserIdAndHuntId($huntId: Int, $userId: Int) {
    getGuessMultiByUserIdAndHuntId(huntId: $huntId, userId: $userId) {
      predictionMulti
    }
  }
`;

export const ADMIN_GETGUESSESBYHUNTID = gql`
  query getGuessesByHuntId($id: Int) {
    getGuessesByHuntId(huntId: $id) {
      id
      prediction
      kickName
      userId
      isWinner
      User {
        displayName
      }
    }
  }
`;
