import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'reactstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CONFIG_BY_KEY } from '../../queries/queries';
import { ADMIN_UPDATE_CONFIG_BY_KEY } from '../../queries/mutations';
import { sweetAlertToast } from '../../helpers';

const AdminGTB = () => {
  const [bonusHunts, setBonusHunts] = useState([]);
  const [challongeUrl, setChallongeUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState(false);
  const navigate = useNavigate();
  let configObject = {};

  const [updateConfigByKey] = useMutation(ADMIN_UPDATE_CONFIG_BY_KEY);
  const [configQuery] = useLazyQuery(CONFIG_BY_KEY, {
    variables: {
      configKey: 'challongeUrl'
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (e) => {
      const configs = e.getConfigByKey;
      configObject[configs.configKey] = configs.configValue;
      setConfig(configObject);
      setChallongeUrl(configs.configValue);
    },
    onError: () => console.log('API Request failed - if this keeps happening, report to developer!')
  });

  const updateChallongeUrl = () => {
    updateConfigByKey({
      variables: {
        configKey: 'challongeUrl',
        configValue: challongeUrl
      }
    }).then((result) => {
      sweetAlertToast(`Updated challonge url!`);
    });
  };

  useEffect(() => {
    setLoading(true);
    axios.get(`https://bht.bet/api/hf8EXkX0UbkaB4kgF8FzciDVdNTHMLO1/hunts`).then((response) => {
      const hunts = response.data;
      setBonusHunts(hunts.reverse().splice(0, 25));
      setLoading(false);
    });
    configQuery();
  }, []);

  return (
    <div className="row">
      <div className="col-12" style={{ marginBottom: 25 }}>
        <div className="card text-bg-dark">
          <div className="card-body">
            <div className="mb-3">
              <label className="form-label">Challonge URL</label>
              <div className="container">
                <div className="row">
                  <div className="col-10">
                    <input
                      type="text"
                      defaultValue={config.challongeUrl}
                      className="form-control"
                      onChange={(e) => setChallongeUrl(e.target.value)}
                    />
                  </div>
                  <div className="col-2">
                    <a className="btn btn-success btn-block" onClick={updateChallongeUrl}>
                      Update URL
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="card text-bg-dark">
          <div className="card-header">GTB</div>
          <div className="card-body p-0">
            <table className="table table-dark table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Bonuses</th>
                  <th></th>
                </tr>
              </thead>
              {loading ? (
                <Spinner />
              ) : (
                <tbody>
                  {bonusHunts.length > 0 ? (
                    <>
                      {bonusHunts.map((row, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{row.id}</th>
                            <td>{row.name}</td>
                            <td>
                              {row.bonus_count_opened} / {row.bonus_count_total}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <ButtonGroup>
                                <Button color="primary" onClick={() => navigate(`gtb/${row.id}`)}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminGTB;
