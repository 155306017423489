import React from 'react';
import { Spinner } from 'reactstrap';

const LoadingIndicator = () => {
  return (
    <div
      style={{
        display: 'flex',
        paddingBottom: '50px',
        paddingTop: '50px',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
    </div>
  );
};

export default LoadingIndicator;
