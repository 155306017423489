import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { useLazyQuery } from '@apollo/client';
import { CONFIG_BY_KEY } from '../../queries/queries';
import LoadingIndicator from '../common/LoadingIndicator';

const TournamentBracketIndex = () => {
  const [challongeUrl, setChallongeUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const [configQuery] = useLazyQuery(CONFIG_BY_KEY, {
    variables: {
      configKey: 'challongeUrl'
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (e) => {
      const configs = e.getConfigByKey;
      setChallongeUrl(configs.configValue);
      setLoading(false);
    },
    onError: () => console.log('API Request failed - if this keeps happening, report to developer!')
  });

  useEffect(() => {
    setLoading(true);
    configQuery();
  }, []);

  return (
    <>
      <div className="container p-0">
        <div className="hero-section">
          <div className="row mx-auto w-100">
            {loading ? (
              <LoadingIndicator />
            ) : (
              <div className="col-12">
                <div className="tournamentWrapper">
                  {challongeUrl && (
                    <iframe
                      src={`${challongeUrl}/module`}
                      width="100%"
                      height="500"
                      frameBorder="0"
                      scrolling="auto"></iframe>
                  )}
                </div>
                <div className="tournamentButton">
                  {challongeUrl && (
                    <a
                      href={`${challongeUrl}/predictions`}
                      className="btn-tournament"
                      target={`_blank`}>
                      Enter submission now
                      <FontAwesomeIcon
                        style={{ marginLeft: 15 }}
                        icon={faArrowRightLong}
                        className={'exitModalCross'}
                        fontSize={24}
                      />
                    </a>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TournamentBracketIndex;
