import React from 'react';
import Body from './common/Body';
import { Route, Routes, useLocation } from 'react-router-dom';
import Page from './common/Page';
import HomepageIndex from './pages/HomepageIndex';
import AccountIndex from './pages/AccountIndex';
import AdminIndex from './pages/AdminIndex';

import { AuthProvider } from '../context/AuthContext';
import { useSelector } from 'react-redux';
import TopWagersIndex from './pages/TopWagersIndex';
import TournamentBracketIndex from './pages/TournamentBracketIndex';
import RewardsIndex from './pages/RewardsIndex';
import BonusHuntIndex from './pages/BonusHuntIndex';
const Routing = () => {
  const location = useLocation();
  const user = useSelector(({ UserReducer }) => UserReducer.user);

  return (
    <AuthProvider>
      <Body>
        <Routes location={location}>
          <Route
            exact
            path="/"
            element={
              <Page title={'Home'}>
                <HomepageIndex />
              </Page>
            }
          />
          <Route
            exact
            path="/top-wagers"
            element={
              <Page title={'Home'}>
                <TopWagersIndex />
              </Page>
            }
          />
          <Route
            exact
            path="/tournament-bracket"
            element={
              <Page title={'Home'}>
                <TournamentBracketIndex />
              </Page>
            }
          />
          <Route
            exact
            path="/rewards"
            element={
              <Page title={'Home'}>
                <RewardsIndex />
              </Page>
            }
          />
          <Route
            exact
            path="/bonus-hunt"
            element={
              <Page title={'Home'}>
                <BonusHuntIndex />
              </Page>
            }
          />

          <Route
            exact
            path="/account"
            element={
              <Page title={'Account'}>
                <AccountIndex />
              </Page>
            }
          />
          {user?.isAdmin && (
            <Route
              exact
              path="/admin/*"
              element={
                <Page title={'Admin'}>
                  <AdminIndex />
                </Page>
              }
            />
          )}
        </Routes>
      </Body>
    </AuthProvider>
  );
};

export default Routing;
