import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { serverPort, serverScheme, serverUrl } from '../config';
import { doLogout } from '../store/actions/UserActions';
import { store } from '../store/index';
import { sweetAlertToast } from '../helpers';

let serverHttpLinkString;
if (serverPort) {
  serverHttpLinkString = `${serverScheme}://${serverUrl}:${serverPort}`;
} else {
  serverHttpLinkString = `${serverScheme}://${serverUrl}`;
}

const httpLink = createHttpLink({
  uri: `${serverHttpLinkString}/graphql`
});

const authLink = setContext((request, previousContext) => {
  const userAccessToken = localStorage.getItem('jwtToken');
  return {
    headers: {
      ...previousContext.headers,
      authorization: userAccessToken
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const graphQLError of graphQLErrors) {
      const message = graphQLError.message;
      sweetAlertToast(`${message}`, `warning`);
    }
  }
  if (networkError) {
    if (networkError.statusCode === 401) {
      store.dispatch(doLogout());
    }
  }
});

export const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache()
});
