import jwt_decode from 'jwt-decode';

const INITIAL_STATE = {
  user: null,
  isLoggedIn: false,
  hasConfirmedAge: false,
  userAccessToken: null,
  userIdToken: null,
  userAccessTokenExpiration: null
};

const UserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DECODE_JWT_TOKEN': {
      const decoded = jwt_decode(action.payload);
      return {
        ...state,
        user: decoded.user,
        userAccessToken: action.payload,
        userAccessTokenExpiration: decoded.exp
      };
    }
    case 'SET_USER':
      return {
        ...state,
        user: action.payload
      };
    case 'DO_LOGOUT':
      localStorage.removeItem('jwtToken');
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        userAccessToken: null,
        userAccessTokenExpiration: null
      };
    case 'SET_LOGGED_IN':
      return {
        ...state,
        isLoggedIn: action.payload
      };
    case 'SET_CONFIRM_AGE':
      return {
        ...state,
        hasConfirmedAge: action.payload
      };
    case 'SET_USER_ACCESS_TOKEN':
      return {
        ...state,
        userAccessToken: action.payload
      };
    case 'SET_USER_ID_TOKEN':
      return {
        ...state,
        userIdToken: action.payload
      };
    case 'SET_USER_ACCESS_TOKEN_EXPIRATION':
      return {
        ...state,
        userAccessTokenExpiration: action.payload
      };
    default:
      return state;
  }
};

export default UserReducer;
