import React, { useEffect, useState } from 'react';
import hero from '../../resources/hero.png';
import PokerChip from '../../resources/pokerchip.png';
import RewardsBanner from '../../resources/reward-font.png';
import FollowBanner from '../../resources/follow-me-font.png';
import JoinUsBanner from '../../resources/join-us-font.png';
import Icon from '@mdi/react';
import { mdiYoutube, mdiVideoOutline, mdiEye } from '@mdi/js';
import { useLazyQuery } from '@apollo/client';
import { HOMEPAGE_VIDEOS } from '../../queries/queries';
import { Spinner } from 'reactstrap';

const Index = () => {
  const [videos, setVideos] = useState([]);
  const [fullKickVideo, setFullKickVideo] = useState(null);
  const [kickLoading, setKickLoading] = useState(false);
  const [kickClips, setKickClips] = useState([]);

  const [getVideos, { loading }] = useLazyQuery(HOMEPAGE_VIDEOS, {
    variables: {
      limit: 3
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (e) => {
      setVideos(e.getVideos);
    },
    onError: () => console.log('API Request failed - if this keeps happening, report to developer!')
  });

  const setClipVideo = (row) => {
    if (fullKickVideo) {
      // eslint-disable-next-line no-undef
      var player = videojs('hls-example');
      player.dispose();
    }
    setFullKickVideo(null);

    setTimeout(() => {
      setFullKickVideo(row);
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        var player = videojs('hls-example');
        player.play();
      }, 250);
    }, 250);
  };

  useEffect(() => {
    setKickLoading(true);

    fetch('https://kick.com/api/v2/channels/buhlockay/clips?cursor=0&sort=date&time=month')
      .then((response) => response.json())
      .then((data) => {
        setKickLoading(false);
        setKickClips(data.clips.splice(0, 3));
      });
  }, []);

  useEffect(() => {
    getVideos();
  }, []);

  return (
    <>
      <img src={hero} style={{ maxWidth: '100%' }} alt={`Hero image`} />

      <div className="container p-0">
        <div className="hero-section">
          <div className="row mx-auto w-100">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="heading">Entertaining content & rewards</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="col-md-12">
          <div className="infoBoxes">
            <div className="infoBox">
              <div className="infoBoxIcon">
                <img src={PokerChip} alt="" />
              </div>
              <div className="infoBoxText">
                <img src={RewardsBanner} alt="" />
                <p>
                  Join the BEST Casino with the <span className="textHighlight">Best Rewards!</span>
                </p>
              </div>
            </div>
            <div className="infoBox">
              <div className="infoBoxIcon">
                <img src={PokerChip} alt="" />
              </div>
              <div className="infoBoxText">
                <img src={FollowBanner} alt="" />
                <ul className="social-icons">
                  <li>
                    <a target="_blank" href="https://twitter.com/buhlockay_" rel="noreferrer">
                      <img src="/twitter.svg" alt="twitter" className="img-fluid" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://discord.gg/bJRY2weub4" rel="noreferrer">
                      <img src="/discord.svg" alt="discord" className="img-fluid" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://kick.com/buhlockay" rel="noreferrer">
                      <img src="/kick.svg" alt="kick" className="img-fluid" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.youtube.com/@buhlockay_" rel="noreferrer">
                      <img src="/youtube.svg" alt="youtube" className="img-fluid" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="infoBox">
              <div className="infoBoxIcon">
                <img src={PokerChip} alt="" />
              </div>
              <div className="infoBoxText">
                <img src={JoinUsBanner} alt="" />
                <p>
                  <span className="textHighlight">Join the discord</span> to stay up to date on
                  latest news, giveaways and prizes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <section className="sectionWrapper">
            <div className="sectionTitle youtube">
              <Icon path={mdiYoutube} size={2} color="#EF4444" /> Youtube highlights
            </div>
            <div className="sectionContent">
              {loading ? (
                <Spinner />
              ) : (
                <div className="videoContainer">
                  {videos && (
                    <>
                      {videos.map((row, index) => {
                        return (
                          <div className="video" key={index}>
                            <a
                              href={`https://www.youtube.com/watch?v=${row.id}`}
                              target={'_blank'}
                              rel="noreferrer">
                              <div className="videoImageWrapper">
                                <div className="gradientOverlay"></div>
                                <img src={row.thumbnail} alt="" />
                              </div>
                              <div className="videoTitle">
                                <div className="videoTitleLeft">{row.title}</div>
                                <div className="videoTitleRight">
                                  <Icon path={mdiEye} size={1} color="#64748B" />
                                  {row.viewCount}
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              )}
            </div>
          </section>
          <section className="sectionWrapper">
            <div className="sectionTitle stream">
              <Icon path={mdiVideoOutline} size={2} color="#22C55E" /> Stream highlights
            </div>
            <div className="sectionContent">
              {fullKickVideo && (
                <div className="kickVideoFull" style={{ maxWidth: '100%' }}>
                  <video id="hls-example" className="video-js vjs-default-skin" controls>
                    <source src={fullKickVideo.clip_url} type="application/x-mpegURL" />
                  </video>
                </div>
              )}
              {kickLoading ? (
                <Spinner />
              ) : (
                <div className="videoContainer">
                  {kickClips && (
                    <>
                      {kickClips.map((row, index) => {
                        return (
                          <div className="video" key={index}>
                            <a onClick={() => setClipVideo(row)} target={'_blank'} rel="noreferrer">
                              <div className="videoImageWrapper">
                                <div className="gradientOverlay"></div>
                                <img src={row.thumbnail_url} alt="" />
                              </div>
                              <div className="videoTitle">
                                <div className="videoTitleLeft">{row.title}</div>
                                <div className="videoTitleRight">
                                  <Icon path={mdiEye} size={1} color="#64748B" />
                                  {row.view_count}
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Index;
