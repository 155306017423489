import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import AdminGTB from './AdminGTB';
import AdminGTBView from './AdminGTBView';

const AdminIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <div className="container p-0">
        <div className="row">
          <div className="col-md-12">
            <Routes location={location}>
              <Route path="" element={<AdminGTB />} />
              <Route path="gtb/:id" element={<AdminGTBView />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminIndex;
