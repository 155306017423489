export const doLogout = () => ({
  type: 'DO_LOGOUT',
  payload: true
});
export const setUserAccessToken = (userAccessToken) => ({
  type: 'SET_USER_ACCESS_TOKEN',
  payload: userAccessToken
});

export const setUserIdToken = (userIdToken) => ({
  type: 'SET_USER_ID_TOKEN',
  payload: userIdToken
});

export const setUser = (user) => ({
  type: 'SET_USER',
  payload: user
});

export const setUserAccessTokenExpiration = (userAccessToken) => ({
  type: 'SET_USER_ACCESS_TOKEN_EXPIRATION',
  payload: userAccessToken
});

export const decodeJWTToken = (jwtToken) => ({
  type: 'DECODE_JWT_TOKEN',
  payload: jwtToken
});

export const setIsLoggedIn = (isLoggedIn) => ({
  type: 'SET_LOGGED_IN',
  payload: isLoggedIn
});
