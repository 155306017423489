import { persistReducer, persistStore } from 'redux-persist';
import rootReducer from './reducers';
import { compose, createStore } from 'redux';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = (preloadedState) => {
  return createStore(
    persistedReducer,
    preloadedState,
    compose(
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (noop) => noop
    )
  );
};

export const store = configureStore();
export const persistor = persistStore(store);
