import React, { useEffect } from 'react';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Header from './Header';
import { serverHttpLinkString } from '../../config';
import { useDispatch } from 'react-redux';
import { decodeJWTToken, setIsLoggedIn, setUser } from '../../store/actions/UserActions';
import jwt_decode from 'jwt-decode';

const Body = (props) => {
  const dispatch = useDispatch();

  const handleEventListener = (event) => {
    const { token, ok } = event.data;

    if (ok) {
      localStorage.setItem('jwtToken', token);
      const decodedToken = jwt_decode(token);
      dispatch(decodeJWTToken(token));
      dispatch(setUser(decodedToken.user));
      dispatch(setIsLoggedIn(true));
    }
  };

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.origin != `${serverHttpLinkString}`) return;

      handleEventListener(event);
    });
  }, []);

  return (
    <div>
      <div className="content">
        <Header />
        <div className="container" style={{ padding: '50px 0' }}>
          {props?.children}
        </div>
      </div>

      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12 footerContent">
              <ul className="pl-0 mb-0 d-flex social-icons">
                <li>
                  <a target="_blank" href="https://twitter.com/buhlockay_" rel="noreferrer">
                    <img src="/twitter.svg" alt="twitter" className="img-fluid" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://discord.gg/bJRY2weub4" rel="noreferrer">
                    <img src="/discord.svg" alt="discord" className="img-fluid" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://kick.com/buhlockay" rel="noreferrer">
                    <img src="/kick.svg" alt="kick" className="img-fluid" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.youtube.com/@buhlockay_" rel="noreferrer">
                    <img src="/youtube.svg" alt="youtube" className="img-fluid" />
                  </a>
                </li>
              </ul>
              <p className="mb-0">Buhlockay</p>
              <p className="mb-0">© 2023 All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Body;
