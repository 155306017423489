import { gql } from '@apollo/client';

export const userSubmitGTBQuery = gql`
  mutation userSubmitGTB($userId: Int, $huntId: Int, $prediction: Float, $kickname: String) {
    userSubmitGTB(userId: $userId, huntId: $huntId, prediction: $prediction, kickname: $kickname) {
      id
    }
  }
`;
export const userSubmitGTBMultiQuery = gql`
  mutation userSubmitGTBMulti($userId: Int, $huntId: Int, $prediction: Float) {
    userSubmitGTBMulti(userId: $userId, huntId: $huntId, prediction: $prediction) {
      id
    }
  }
`;

export const ADMIN_TOGGLEGTB = gql`
  mutation toggleGTB($huntId: Int, $gtbEnabled: Boolean) {
    toggleGTB(huntId: $huntId, gtbEnabled: $gtbEnabled) {
      gtbEnabled
    }
  }
`;

export const ADMIN_UPDATEHUNTPAYOUT = gql`
  mutation setHuntPayout($huntId: Int, $payout: Float, $multiplier: Float) {
    setHuntPayout(huntId: $huntId, payout: $payout, multiplier: $multiplier) {
      id
      huntId
      payout
      multiplier
    }
  }
`;

export const ADMIN_UPDATEFIRSTPLACE = gql`
  mutation setHuntWinnerFirst($huntId: Int, $user: Int) {
    setHuntWinnerFirst(huntId: $huntId, user: $user) {
      id
    }
  }
`;

export const ADMIN_UPDATESECONDPLACE = gql`
  mutation setHuntWinnerSecond($huntId: Int, $user: Int) {
    setHuntWinnerSecond(huntId: $huntId, user: $user) {
      id
    }
  }
`;

export const ADMIN_UPDATETHIRDPLACE = gql`
  mutation setHuntWinnerThird($huntId: Int, $user: Int) {
    setHuntWinnerThird(huntId: $huntId, user: $user) {
      id
    }
  }
`;

export const ADMIN_UPDATEMULTIPLACE = gql`
  mutation setHuntWinnerMulti($huntId: Int, $user: Int) {
    setHuntWinnerMulti(huntId: $huntId, user: $user) {
      id
    }
  }
`;

export const ADMIN_UPDATE_CONFIG_BY_KEY = gql`
  mutation updateConfigByKey($configKey: String, $configValue: String) {
    updateConfigByKey(configKey: $configKey, configValue: $configValue) {
      configKey
      configValue
    }
  }
`;
