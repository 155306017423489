import React, { useEffect, useState } from 'react';
import firstPlaceBadge from '../../resources/firstPlace.png';
import secondPlaceBadge from '../../resources/secondPlace.png';
import thirdPlaceBadge from '../../resources/thirdPlace.png';
import { useLazyQuery } from '@apollo/client';
import { HOMEPAGE_LEADERBOARD, HOMEPAGE_LEADERBOARDPRIZES } from '../../queries/queries';
import { formatToCurrency } from '../../helpers';

const TopWagersIndex = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [leaderboardPrizes, setLeaderboardPrizes] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [leaderboardRemaining, setLeaderboardRemaining] = useState([]);
  const [leaderboardFirst, setLeaderboardFirst] = useState(null);
  const [leaderboardSecond, setLeaderboardSecond] = useState(null);
  const [leaderboardThird, setLeaderboardThird] = useState(null);

  const [getLeaderboard] = useLazyQuery(HOMEPAGE_LEADERBOARD, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (e) => {
      setLeaderboard(e.getLeaderboard);
    },
    onError: () => console.log('API Request failed - if this keeps happening, report to developer!')
  });

  const [getLeaderboardPrizes] = useLazyQuery(HOMEPAGE_LEADERBOARDPRIZES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (e) => {
      setLeaderboardPrizes(e.getLeaderboardPrizes);
    },
    onError: () => console.log('API Request failed - if this keeps happening, report to developer!')
  });

  const getRewardAtPosition = (position) => {
    return leaderboardPrizes[position - 1]
      ? formatToCurrency(leaderboardPrizes[position - 1].Reward)
      : '';
  };

  useEffect(() => {
    if (leaderboard && leaderboard.length > 0) {
      setLeaderboardFirst(leaderboard.slice(0, 1)[0]);
      setLeaderboardSecond(leaderboard.slice(1, 2)[0]);
      setLeaderboardThird(leaderboard.slice(2, 3)[0]);
      setLeaderboardRemaining(leaderboard.slice(3, 50));
    }
  }, [leaderboard]);

  useEffect(() => {
    getLeaderboard();
    getLeaderboardPrizes();
  }, []);

  return (
    <>
      <div className="container p-0"></div>
      <div className="container">
        <div className="col-md-12">
          <div className="contentBoxes">
            <div className="contentBox">
              <div className="contentBoxText">
                <div className="hero-section">
                  <div className="row mx-auto w-100">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="heading">Buhlockay&apos;s Weekly $1500 Wager Leaderboard</div>
                      <p>
                        Sign-up using code <span className="textHighlight">"mullet"</span> to
                        participate
                      </p>
                    </div>
                  </div>
                </div>
                <div className="podiumWinners">
                  {leaderboardSecond && (
                    <div className="podium second">
                      <div className="podiumText">
                        <p className="podiumPlayerName">{leaderboardSecond.PlayerName}</p>
                        <p className="podiumPlayerPosition">2nd place</p>
                        <p className="podiumPlayerWagered">
                          Wagered{' '}
                          <span className="colored">
                            {formatToCurrency(leaderboardSecond.WageredAmount)}
                          </span>
                        </p>
                      </div>
                      <div className="podiumPosition">
                        <img src={secondPlaceBadge} alt="" />
                      </div>
                      <div className="podiumPrize">{getRewardAtPosition(2)}</div>
                    </div>
                  )}
                  {leaderboardFirst && (
                    <div className="podium first">
                      <div className="podiumText">
                        <p className="podiumPlayerName">{leaderboardFirst.PlayerName}</p>
                        <p className="podiumPlayerPosition">1st place</p>
                        <p className="podiumPlayerWagered">
                          Wagered{' '}
                          <span className="colored">
                            {formatToCurrency(leaderboardFirst.WageredAmount)}
                          </span>
                        </p>
                      </div>
                      <div className="podiumPosition">
                        <img src={firstPlaceBadge} alt="" />
                      </div>
                      <div className="podiumPrize">{getRewardAtPosition(1)}</div>
                    </div>
                  )}
                  {leaderboardThird && (
                    <div className="podium third">
                      <div className="podiumText">
                        <p className="podiumPlayerName">{leaderboardThird.PlayerName}</p>
                        <p className="podiumPlayerPosition">3rd place</p>
                        <p className="podiumPlayerWagered">
                          Wagered{' '}
                          <span className="colored">
                            {formatToCurrency(leaderboardThird.WageredAmount)}
                          </span>
                        </p>
                      </div>
                      <div className="podiumPosition">
                        <img src={thirdPlaceBadge} alt="" />
                      </div>
                      <div className="podiumPrize">{getRewardAtPosition(3)}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="contentBoxes" style={{ marginTop: 50 }}>
            <div className="contentBox">
              <div className="contentBoxText p-0">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Username</th>
                      <th>Wagered</th>
                      <th>Prize Won</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaderboardRemaining.map((entry, idx) => (
                      <tr key={idx}>
                        <td>{idx + 4}</td>
                        <td>{entry.PlayerName}</td>
                        <td className={`wagered`}>{formatToCurrency(entry.WageredAmount)}</td>
                        <td className={`prize`}>{getRewardAtPosition(idx + 4)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopWagersIndex;
