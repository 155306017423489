import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingIndicator from '../common/LoadingIndicator';
import { GET_GUESSBYUSERANDHUNT, GET_HUNTBYHUNTID } from '../../queries/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import bcgameLogo from '../../resources/bcgameLogo.png';
import bonusHuntTop from '../../resources/bonusHunt.png';
import { formatToCurrency, sweetAlertToast } from '../../helpers';
import { useSelector } from 'react-redux';
import { userSubmitGTBQuery } from '../../queries/mutations';
import PokerChip from '../../resources/pokerchip.png';
import secondSmall from '../../resources/secondSmall.png';
import firstSmall from '../../resources/firstSmall.png';
import thirdSmall from '../../resources/thirdSmall.png';
import RewardsBanner from '../../resources/reward-font.png';
import FollowBanner from '../../resources/follow-me-font.png';
import JoinUsBanner from '../../resources/join-us-font.png';

const BonusHuntIndex = () => {
  const params = useParams();
  const user = useSelector(({ UserReducer }) => UserReducer.user);
  const [activeHunt, setActiveHunt] = useState();
  const [loading, setLoading] = useState(true);
  const [dbHunt, setDbHunt] = useState(null);
  const latestHuntId = useRef();
  const [prediction, setPrediction] = useState(null);
  const [kickName, setKickName] = useState(null);
  const [guess, setGuess] = useState([]);

  const [userSubmitGTB] = useMutation(userSubmitGTBQuery);

  const [dbHuntQuery, { dbloading, dbrefetch }] = useLazyQuery(GET_HUNTBYHUNTID, {
    variables: {
      huntId: parseInt(activeHunt?.id)
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (e) => {
      setDbHunt(e.getHuntByHuntId ?? []);
    },
    onError: () => console.log('API Request failed - if this keeps happening, report to developer!')
  });

  const [getGuess, { loadingGuess, refetch }] = useLazyQuery(GET_GUESSBYUSERANDHUNT, {
    variables: {
      huntId: parseInt(activeHunt?.id),
      userId: user?.id ?? null
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (e) => {
      const data = e.getGuessByUserIdAndHuntId ?? null;
      console.log(data);
      if (data?.prediction) {
        setGuess(data);
      } else {
        setGuess(null);
      }
    },
    onError: () => console.log('API Request failed - if this keeps happening, report to developer!')
  });

  const submitPrediction = () => {
    if (parseFloat(prediction) > 0 && kickName) {
      if (window.confirm(`Are you sure?`)) {
        userSubmitGTB({
          variables: {
            userId: parseInt(user.id),
            huntId: parseInt(activeHunt.id),
            prediction: parseFloat(prediction),
            kickname: kickName
          }
        }).then((result) => {
          sweetAlertToast(`Your guess the balance was submitted!`);
          refetch();
        });
      }
    } else {
      sweetAlertToast(`You need to add a prediction!`, `warning`);
    }
  };

  useEffect(() => {
    setLoading(true);

    axios.get(`https://bht.bet/api/hf8EXkX0UbkaB4kgF8FzciDVdNTHMLO1/hunts`).then((response) => {
      const hunts = response.data.reverse();
      const lastElement = hunts[0];
      latestHuntId.current = lastElement.id;
      if (params.id) {
        latestHuntId.current = params.id;
      }

      axios
        .get(`https://bht.bet/api/hf8EXkX0UbkaB4kgF8FzciDVdNTHMLO1/hunts/${latestHuntId.current}`)
        .then((response) => {
          setActiveHunt(response.data);
          setLoading(false);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
      if (user) {
        getGuess();
      }
    });

    const interval = setInterval(() => {
      if (latestHuntId.current) {
        axios
          .get(`https://bht.bet/api/hf8EXkX0UbkaB4kgF8FzciDVdNTHMLO1/hunts/${latestHuntId.current}`)
          .then((response) => {
            setActiveHunt(response.data);

            if (activeHunt) {
              // dbHuntQuery();
            }

            setLoading(false);
          });
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [params.id]);

  useEffect(() => {
    dbHuntQuery();
  }, [activeHunt]);

  return (
    <>
      <div className="container p-0">
        <div className="hero-section">
          <div className="row mx-auto w-100">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {loading ? (
                <LoadingIndicator />
              ) : (
                <>
                  <div className="col-12">
                    <div className="rewardsTopWrapper">
                      <div className="rewardsTopLeft">
                        <h2>Guess & Win</h2>
                        <p>
                          Get a chance to spin free spins, predict the outcome and win big prizes!
                        </p>
                      </div>
                      <div className="rewardsTopRight">
                        <img src={bonusHuntTop} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="gtbPredictionWrapper">
                      <div className="predictionBox"></div>
                      <div className="predictionWinners"></div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="gtbBonusesWrapper">
                      <div className="gtbFAQ">
                        <div className="infoBoxes">
                          <div className="infoBox">
                            <div className="infoBoxText center">
                              <div
                                style={{
                                  lineHeight: '40px',
                                  fontSize: '18px',
                                  fontWeight: 'bold'
                                }}>
                                Predict now
                              </div>
                              {user ? (
                                <>
                                  {!dbHunt?.gtbEnabled ? (
                                    <div className="guessingClosed">
                                      Guessing is not allowed yet!
                                    </div>
                                  ) : (
                                    <form>
                                      <input
                                        type="text"
                                        className="input-buhlockay"
                                        placeholder={`Kick username..`}
                                        defaultValue={guess ? guess.kickName : kickName}
                                        readOnly={!!guess}
                                        onChange={(e) => setKickName(e.target.value)}
                                      />
                                      <input
                                        type="number"
                                        min={1}
                                        step={1}
                                        className="input-buhlockay"
                                        placeholder={`Your prediction..`}
                                        defaultValue={guess ? guess.prediction : prediction}
                                        readOnly={!!guess}
                                        onChange={(e) => setPrediction(e.target.value)}
                                      />
                                      {!guess && (
                                        <a className="btn-buhlockay" onClick={submitPrediction}>
                                          Submit!
                                        </a>
                                      )}
                                    </form>
                                  )}
                                </>
                              ) : (
                                <div className="guessingClosed">
                                  You must be logged in to predict!
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="gtbBonuses">
                        <div className="row">
                          <div className="col-12" style={{ marginBottom: '20px' }}>
                            <div className="infoBoxes">
                              <div className="infoBox">
                                <div className="infoBoxIcon">
                                  <img src={secondSmall} alt="" />
                                </div>
                                <div className="infoBoxText" style={{ padding: '50px 20px' }}>
                                  {dbHunt?.secondPlace && (
                                    <form>
                                      <input
                                        type="text"
                                        className="input-buhlockay"
                                        readOnly
                                        defaultValue={dbHunt?.secondPlaceGuess?.kickName}
                                      />
                                      <input
                                        type="number"
                                        min={1}
                                        step={1}
                                        className="input-buhlockay"
                                        readOnly
                                        defaultValue={dbHunt?.secondPlaceGuess?.prediction}
                                      />
                                    </form>
                                  )}
                                </div>
                              </div>
                              <div className="infoBox">
                                <div className="infoBoxIcon">
                                  <img src={firstSmall} alt="" />
                                </div>
                                <div className="infoBoxText" style={{ padding: '75px 20px' }}>
                                  {dbHunt?.firstPlace && (
                                    <form>
                                      <input
                                        type="text"
                                        className="input-buhlockay"
                                        readOnly
                                        defaultValue={dbHunt?.firstPlaceGuess?.kickName}
                                      />
                                      <input
                                        type="number"
                                        min={1}
                                        step={1}
                                        className="input-buhlockay"
                                        readOnly
                                        defaultValue={dbHunt?.firstPlaceGuess?.prediction}
                                      />
                                    </form>
                                  )}
                                </div>
                              </div>
                              <div className="infoBox">
                                <div className="infoBoxIcon">
                                  <img src={thirdSmall} alt="" />
                                </div>
                                <div className="infoBoxText" style={{ padding: '50px 20px' }}>
                                  {dbHunt?.thirdPlace && (
                                    <form>
                                      <input
                                        type="text"
                                        className="input-buhlockay"
                                        readOnly
                                        defaultValue={dbHunt?.thirdPlaceGuess?.kickName}
                                      />
                                      <input
                                        type="number"
                                        min={1}
                                        step={1}
                                        className="input-buhlockay"
                                        readOnly
                                        defaultValue={dbHunt?.thirdPlaceGuess?.prediction}
                                      />
                                    </form>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="infoBoxes">
                              <div className="infoBox">
                                <div className="infoBoxText">
                                  <div className="upper">{activeHunt?.id}</div>
                                  <div className="lower">Hunt ID</div>
                                </div>
                              </div>
                              <div className="infoBox">
                                <div className="infoBoxText">
                                  <div className="upper">{activeHunt?.bonuses.length}</div>
                                  <div className="lower"># of bonuses</div>
                                </div>
                              </div>
                              <div className="infoBox">
                                <div className="infoBoxText">
                                  <div className="upper">{activeHunt?.info_start_cost}</div>
                                  <div className="lower">Starting Bal.</div>
                                </div>
                              </div>
                              <div className="infoBox">
                                <div className="infoBoxText">
                                  <div className="upper">{activeHunt?.info_amount_won}</div>
                                  <div className="lower">Ending Bal.</div>
                                </div>
                              </div>
                              <div className="infoBox">
                                <div className="infoBoxText">
                                  <div className="upper">{activeHunt?.info_highest_multi}</div>
                                  <div className="lower">Highest X</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12" style={{ marginTop: 50 }}>
                    <div className="gtbBonusesWrapper">
                      <div className="gtbFAQ">
                        <div className="infoBoxes">
                          <div className="infoBox">
                            <div className="infoBoxText">
                              <div
                                style={{
                                  lineHeight: '40px',
                                  fontSize: '18px',
                                  fontWeight: 'bold'
                                }}>
                                FAQ
                              </div>
                              <div style={{ fontWeight: 'bold' }}>What is bonus hunt?</div>
                              <p>
                                Bonus hunt is a collection of bonus buys accumulated from starting
                                balance. During bonus hunt viewers can predict the ending balance.
                              </p>
                              <div style={{ fontWeight: 'bold' }}>How to participate?</div>
                              <p>
                                To be able to participate there must be an active bonus hunt. These
                                happen randomly although most time they are announced in discord. To
                                be able to predict the outcome of one of these you need to join
                                discord and submit your prediction on “Guess the balance” to acquire
                                a prize, make sure you are watching that stream!
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="gtbBonuses">
                        <div className="contentBoxes">
                          <div className="contentBox">
                            <div className="contentBoxText p-0">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Game</th>
                                    <th className="tableRight">BASE BET</th>
                                    <th className="tableRight">X-Win</th>
                                    <th className="tableRight">Win Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {activeHunt?.bonuses.map((item, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td>{item.name}</td>
                                        <td className="tableRight text-blue">{item.bet_size}</td>
                                        <td className="tableRight text-yellow">
                                          {item.multiplier}
                                        </td>
                                        <td className="tableRight text-green">{item.payout}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BonusHuntIndex;
